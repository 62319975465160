<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back(-1)">公示名单</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">企业名单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="公示名称" class="searchboxItem ci-full">
              <span class="itemLabel">公示名称:</span>
              <div style="font-size:14px">{{publicityInfo.publicityName}}</div>
            </div>
            <div title="公示区域" class="searchboxItem ci-full">
              <span class="itemLabel">公示区域:</span>
              <div style="font-size:14px">{{publicityInfo.areaName}}</div>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="是否安知平台培训" class="searchboxItem ci-full">
              <span class="itemLabel">是否安知平台培训:</span>
              <el-select v-model="searchForm.publicityIsAnzhi" placeholder="请选择" clearable size="small">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                v-model="searchForm.publicityCompanyName"
                clearable
                size="small"
                placeholder="请输入企业名称"
              ></el-input>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="exportSeeExaminationRoom"
              >导出</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="企业名称"
                align="center"
                prop="publicityCompanyName"
                show-overflow-tooltip
              />
              <el-table-column
                label="项目名称"
                align="center"
                prop="publicityItemName"
                show-overflow-tooltip
              />
              <el-table-column
                label="补贴人数"
                align="center"
                prop="publicitySubsidyPersonNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="补贴金额"
                align="center"
                prop="publicitySubsidyAmount"
                show-overflow-tooltip
              />
              <el-table-column
                label="是否安知平台培训"
                align="center"
                prop="publicityIsAnzhi"
                show-overflow-tooltip
              >
                <template slot-scope="{row}">
                    {{row.publicityIsAnzhi?'是':'否'}}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "checkTheList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit:true,
  data() {
    return {
      // 检索数据
      searchForm: {
        publicityIsAnzhi: "", // 是否安知平台培训
        publicityCompanyName: "", // 企业名称
      },
      fdPublicityId:'',
      publicityInfo:{
        publicityName:'',
        areaName:''
      },
    };
  },
  created() {
    this.fdPublicityId = this.$route.query.fdPublicityId
    this.getInfo()
    this.getData()
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        fdPublicityId:this.fdPublicityId,
        publicityIsAnzhi:this.searchForm.publicityIsAnzhi
      };
      console.log(this.searchForm.publicityIsAnzhi);
      if (this.searchForm.publicityCompanyName) {
        params.publicityCompanyName = this.searchForm.publicityCompanyName;
      }
      this.doFetch(
        {
          url: "/biz/new/publicity/companyPageList",
          params,
          pageNum,
        },
      );
    },
    getInfo(){
        this.$post(
            "/biz/new/publicity/getInfo",
            {fdPublicityId:this.fdPublicityId},
        )
        .then((res) => {
            this.publicityInfo = res.data
            console.log( this.publicityInfo);
        })
        .catch(() => {
          return;
        });
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {
        fdPublicityId:this.fdPublicityId
      };
      if (this.searchForm.publicityIsAnzhi) {
        params.publicityIsAnzhi = this.searchForm.publicityIsAnzhi;
      }
      if (this.searchForm.publicityCompanyName) {
        params.publicityCompanyName = this.searchForm.publicityCompanyName;
      }
      this.$post(
        "biz/new/publicity/export",
        params,
      )
        .then((res) => {
          const arr = [...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
  <style lang="less" scoped>
</style>